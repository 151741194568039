:root {
  --color-text: rgba(0, 0, 0, 0.75);
  --color-muted: rgba(0, 0, 0, 0.5);
  --color-text-accent: #05203E;

  --color-accent: #5F98ED;
  --color-accent-2: #6FCF97;

  --color-shape: #e1f1fe;

  --color-button-1: #5F98ED;
  --color-button-1-bg: rgba(39, 132, 237, 0.12);
  --color-button-1-hover-bg: rgba(39, 132, 237, 0.2);
  --color-button-1-click-bg: rgba(39, 132, 237, 0.3);

  --color-button-2: #fff;
  --color-button-2-bg: #2784ED;
  --color-button-2-hover-bg: #5F98ED;
  --color-button-2-click-bg: #518ae0;
  
  --color-bg-transparent: rgba(20, 65, 116, 0.4);
}